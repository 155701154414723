<template>
<ion-page>
  <div v-if="modalVisible" @click="hideModal" class="opm-modal">
    <ion-card style="width: 80%;border-radius: 15px" class="bg" @click.stop="modalVisible = true">
      <ion-card-header style="margin: 15px">
        <img src="https://static.yuzhangmen.cn/logo.jpg?x-oss-process=image/resize,w_140" style="width:70px;box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);border-radius: 10px" />
        <h2 style="color: #000">{{ modalTitle }}</h2>
        <h5 style="margin-top: -2px">{{ modalSubtitle }}</h5>
      </ion-card-header>
      <ion-card-content style="text-align: center;">
        <ion-button download :href="getDownloadUrl()" style="color: #fff;width: 98%">立即下载</ion-button>
      </ion-card-content>
    </ion-card>
  </div>
  <ion-item lines="none" style="--background: #2B2F3F;" mode="md">
    <ion-thumbnail style="--border-radius: 10px;">
      <img src="https://static.yuzhangmen.cn/logo.jpg?x-oss-process=image/resize,w_100" />
    </ion-thumbnail>
    <ion-label class="ion-text-wrap" style="margin-left: 10px;color:#fff">
      鱼掌门App
      <p style="color: #fff;font-size: 12px">指尖上的澳洲房产管家</p>
    </ion-label>
    <ion-button mode="md" slot="end" download :href="getDownloadUrl()" style="color: #fff;" size="medium">下载App</ion-button>
  </ion-item>
  <slot></slot>
  <div class="fixed-button">
    <ion-button mode="md" download :href="getDownloadUrl()" style="color: #fff;">在App内打开</ion-button>
  </div>
  <div class="fixed-button">
    <wx-open-launch-app id="launch-btn" appid="wxc13aabe6200ce437" :extinfo="extInfo">
      <div v-is="'script'" type="text/wxtag-template">
        <div v-is="'style'">.btn{padding:0.8em;opacity:0}</div>
        <button class="btn">在App内打开</button>
      </div>
    </wx-open-launch-app>
  </div>
</ion-page>
</template>

<script>
import { IonPage, IonItem, IonButton, IonLabel, IonThumbnail, IonCard, IonCardHeader, IonCardContent } from '@ionic/vue';
import { defineComponent } from 'vue';
import wx from "weixin-js-sdk"

const defaultShare = { title: document.title, desc: '指尖上的澳洲房产管家', link: location.href, imgUrl: 'https://static.yuzhangmen.cn/logo.jpg', success: () => {
    console.log('设置成功')
  }}

export default defineComponent( {
  name: "OpmPage",
  props: {
    wxShare: {
      type: Object,
      default: () => {
        return defaultShare
      }
    },
    showModal: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String,
      default: '下载鱼掌门App'
    },
    modalSubtitle: {
      type: String,
      default: '指尖上的澳洲房产管家'
    },
    extInfo: {
      type: String,
      default: () => {
        return `yzm:/${location.pathname}`
      }
    }
  },
  data() {
    return {
      appId: 'wx300b2f002a93c35f',
      modalVisible: false,
      getDownloadUrl: () => {
        const mode = window && window.Ionic && window.Ionic.mode;
        return mode === 'ios' ? 'https://apps.apple.com/cn/app/%E9%B1%BC%E6%8E%8C%E9%97%A8/id1477809525' : 'https://a.app.qq.com/o/simple.jsp?pkgname=com.adinnet.ironfish&channel=0002160650432d595942&fromcase=60001';
      }
    }
  },
  mounted() {
    this.initWx()
  },
  watch: {
    wxShare(val) {
      this.wxReady()
    },
    showModal(val) {
      this.modalVisible = val
    }
  },
  methods: {
    hideModal() {
      this.modalVisible = false
      this.$emit('hide', false)
    },
    initWx() {
      this.$axios.get(`/weixin/config?appId=${this.appId}&url=${encodeURIComponent(location.href)}`).then(resp=> {
        const result = resp.result
        if (!wx) {
          return
        }
        wx.config({
          // debug: true,
          appId: this.appId,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature,
          jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'chooseImage', 'previewImage'],
          openTagList: ['wx-open-launch-app']
        });
      })
    },
    wxReady() {
      const share = Object.assign(defaultShare, this.wxShare)
      wx.ready(() => {
        wx.updateAppMessageShareData(share)
        wx.updateTimelineShareData(share)
        wx.onMenuShareWeibo(share)
        wx.onMenuShareQZone(share)
        const btn = document.getElementById('launch-btn')
        btn.addEventListener('launch', () => {
          console.log('success')
        })
        btn.addEventListener('error', (e) => {
          alert(e.detail)
        })
      })
    }
  },
  components: {
    IonPage,
    IonItem,
    IonButton,
    IonLabel,
    IonThumbnail,
    IonCard,
    IonCardHeader,
    IonCardContent
  }
})
</script>

<style scoped>

ion-item {
  --background: transparent;
}

ion-label {
  margin-top: 12px;
  margin-bottom: 12px;
}

ion-button{
  --padding-start: 0.4em;
  --padding-end: 0.4em;
  --color: grey;
}

ion-item h2 {
  font-weight: 600;
}

ion-item ion-icon {
  font-size: 42px;
  margin-right: 8px;
}

ion-item ion-note {
  font-size: 15px;
  margin-right: 12px;
  font-weight: normal;
}

.fixed-button {
  position: fixed;
  bottom:60px;
  display: inline;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  z-index:10000
}

.opm-modal {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center
}

.opm-modal .bg {
  background-image: url("/assets/modal-bg.png");
  background-repeat: no-repeat;
  background-size:cover;
  background-position: bottom right;
}
</style>